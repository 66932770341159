<template>
  <div>
    <v-lazy
      v-for="(question, index) in questions"
      :key="question.id"
      :options="{threshold: .5}"
      class="mb-12"
      min-height="200"
      transition="fade-transition"
    >
      <question-normal
        :correction-mode="true"
        :index="index"
        :manual-correction-mode="teacherMode"
        :question="parsedQuestion(question)"
        :quiz="quiz"
        :show-score="quiz.show_scores || currentUser.teacher"
        :user-id="userId"
      >
        <template slot="grader">
          <open-question-grader
            :answer="answerByQuestionAndUserId(question.id, userId)"
            :current-user="currentUser"
            :label="$t('live.correction.manual')"
            :quiz="quiz"
          />
        </template>
      </question-normal>
    </v-lazy>
  </div>
</template>
<script>
import OpenQuestionGrader from "../../components/questions/open_question_grader.vue"
import QuestionNormal from "./../../components/questions/question_normal.vue"

export default {
  name: "LegacyCorrection",
  components: { OpenQuestionGrader, QuestionNormal },
  props: {
    questions: {},
    quiz: {},
    teacherMode: {},
    userId: {},
    answerByQuestionAndUserId: Function,
    currentUser: {},
  },
  methods: {
    parsedQuestion(question) {
      return {
        id: question.id,
        attributes: question,
        children: this.parsedChoice(question.choices),
        subtitle: () => "",
        subtitleInstructions: () => "",
        isAnswerable: question.type !== "text",
        allowMultipleChoices: question.allow_multiple_choices,
      }
    },

    parsedChoice(choice) {
      return {
        id: choice.id,
        attributes: choice,
      }
    },
  },
}
</script>
