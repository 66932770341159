<template>
  <div>
    <v-card
      class="mb-4"
      v-if="this.teacherMode"
      outlined
      flat
    >
      <v-textarea
        :placeholder="$t('live.correction.generalFeedback.placeholder')"
        :label="$t('live.correction.generalFeedback.label')"
        v-model="public_comment"
        flat
        auto-grow
        rows="1"
        class="pa-4 no-line ma-0"
        hide-details
        @focus="isFocused = true"
        @blur="isFocused = false"
      />

      <v-card-actions
        class="pt-0"
        v-if="isFocused || canSave"
      >
        <v-spacer />
        <eva-button
          dense
          color="primary"
          :disabled="!canSave"
          @click="saveScore"
        >
          {{ $t('live.correction.generalFeedback.save') }}
        </eva-button>
      </v-card-actions>
    </v-card>
    <div v-else>
      <v-alert
        v-if="score.public_comment"
        dense
        text
        class="pb-2"
      >
        <span class="caption">{{ $t('live.correction.generalFeedback.label') }}</span>
        <katex :expr="score.public_comment" />
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import Katex from "../katex-md.vue"

export default {
  name: "StudentGlobalComment",
  components: { Katex },
  props: {
    score: { type: Object, required: true },
    teacherMode: { type: Boolean, default: false },
  },
  data: () => ({
    public_comment: "",
    isFocused: false,
  }),
  computed: {
    canSave() {
      return this.public_comment !== this.score.public_comment
    },
  },
  methods: {
    ...mapActions([
      "updateScoreBySummary",
      "newAppNotification",
    ]),
    saveScore() {
      this.updateScoreBySummary({ ...this.score, public_comment: this.public_comment })
        .then( () => this.newAppNotification({
          message: this.$t("quiz.edit.toast.successMessage"),
          type: "success",
        })
        ).catch((error) =>
          this.newAppNotification({
            message: this.$t("quiz.edit.toast.errorMessage", { error }),
            type: "error",
          })
        )
    },
  },
  mounted() {
    this.public_comment = this.score.public_comment
  },
}
</script>
