<template>
  <div>
    <v-app-bar
      v-if="teacherMode"
      class="app_bar_contained"
      color="transparent"
      dense
      flat
      @click.alt="showEvents = !showEvents"
    >
      <v-btn
        @click="back"
        icon
      >
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-list
        color="transparent"
        class="ml-4"
      >
        <v-list-item-content>
          <span
            v-if="teacherMode && score"
            :class="{ 'blur-names': blurNames}"
          >
            {{ score.name }}
            <time-accommodation-chip
              x-small
              :time-accommodation="timeAccommodation"
              v-if="timeAccommodation.id && quiz?.time_accommodation"
            />
          </span>
          <div
            v-if="teacherMode && score"
            class="caption d-inline-flex align-center d-flex"
          >
            <span :class="{ 'blur-names': blurNames}">
              {{ score.email }}
            </span>

            <template v-if="teacherMode && score && !paperQuiz">
              <div class="ml-2  d-flex">
                <v-icon
                  class="mr-1"
                  x-small
                  v-html="'mdi-clock-time-four-outline'"
                />
                <span>
                  {{ helpers.scoreDuration(score.exam_duration) }}
                </span>
                <v-icon
                  class="mr-1 ml-2"
                  x-small
                  v-html="'mdi-calendar-clock-outline'"
                />
                <span>
                  {{ timeHelpers.formatDateRange(score.started_at, score.finished_at) }}
                </span>
              </div>
              <user-devices
                class="ml-2"
                :devices="score?.devices"
                :multiple-monitor-confidence="score.cheat.multiple_monitor_confidence"
                v-if="score.devices"
                x-small
              />
            </template>
          </div>
          <span v-if="!teacherMode">
            {{ quiz && quiz.name || this.$t('live.correction.loading') }}
          </span>
        </v-list-item-content>
      </v-list>
      <v-spacer />
      <eva-button
        v-if="score.scanned_paper_quiz_sheet_id"
        dense
        text
        :to="{name: 'quizPaperScan', params: {quizId, scanId: score.scanned_paper_quiz_sheet_id}}"
        icon-left="mdi-file-outline"
        :label="$t('live.correction.openScan')"
      />
      <eva-button
        dense
        text
        :loading="downloadingCorrection"
        @click="downloadCopyWithCorrection"
        icon-left="mdi-file-download-outline"
        :label="$t('live.correction.download')"
      />
    </v-app-bar>

    <v-sheet color="transparent">
      <scoreCards
        v-if="score"
        :teacher-mode="teacherMode"
        :score="score"
        :quiz="quiz"
      />

      <cheat-score-alert
        :quiz="quiz"
        :teacher-mode="teacherMode"
      />

      <div class="mt-2">
        <evalmee-quiz-events-pair-timeline
          v-if="score && teacherMode && quiz.quiz_type === 'exam' && score.events_report"
          :events-pairs="score.events_report?.events_pairs || []"
          :events="studentEvents(score.id)"
          :events-reports="score.events_report?.report"
          :exercises="exercises"
          class="mb-4"
        />

        <evalmee-quiz-events-report
          :score="score"
          v-if="showEvents"
        />

        <evalmee-quiz-events
          v-if="showEvents"
          :score-id="score.id"
        />

        <student-global-comment
          v-if="score"
          :score="score"
          :teacher-mode="teacherMode"
          class="mb-12"
        />
        <v-alert
          v-if="teacherMode && !showLegacyCorrection"
          :icon="false"
          class="mb-5"
          text
          type="info"
        >
          {{ $t('live.correction.questionOrder') }}
        </v-alert>

        <out-of-time-several-alert
          v-if="quiz && score && currentUser.teacher"
          :answers="answers"
          :score="score"
          :quiz="quiz"
          :quiz-instance="quizInstance"
          class="mb-5"
        />


        <scan-modale
          v-if="paperQuiz"
          :quiz="quiz"
          :score="score"
        />

        <div
          v-if="!quiz"
          class="center"
        >
          <preloader />
        </div>

        <div v-else>
          <template v-if="showLoader">
            <v-skeleton-loader
              v-for="i in quiz.questions_count"
              :key="`sk-${i}`"
              class="mb-2"
              height="200"
              type="card"
            />
          </template>

          <div
            v-for="section in quizInstanceSections"
            :key="section.id"
          >
            <katex-md
              class="text-h5"
              v-if="section.attributes.name"
              :expr="section.attributes.name"
            />

            <katex-md
              class="mt-2"
              v-if="section.attributes.description"
              :expr="section.attributes.description"
            />

            <template v-if="!currentCorrectionLoading">
              <v-lazy
                v-for="(exercise) in section.children"
                :key="exercise.uuid"
                :options="{threshold: .1}"
                class="mb-12"
                min-height="200"
                transition="fade-transition"
              >
                <exercise-student
                  correction-mode
                  :exercise="exercise"
                  :manual-correction-mode="teacherMode"
                  :quiz="quiz"
                  :quiz-instance="quizInstance"
                  :user-id="userId"
                  :quiz-score="score"
                >
                  <template #grader="{answer, question}">
                    <v-sheet>
                      <open-question-grader
                        v-if="answer"
                        :answer="answer"
                        :current-user="currentUser"
                        :label="$t('live.correction.manual')"
                        :question="question"
                        :quiz="quiz"
                      />
                    </v-sheet>
                  </template>
                </exercise-student>
              </v-lazy>
            </template>
          </div>

          <!--          Legacy correction    -->
          <legacyCorrection
            v-if="showLegacyCorrection"
            :answer-by-question-and-user-id="answerByQuestionAndUserId"
            :current-user="currentUser"
            :questions="questions"
            :quiz="quiz"
            :teacher-mode="teacherMode"
            :user-id="userId"
          />
          <!--          End Legacy correction    -->
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import preloader from "../../components/shared/preloader.vue"
import ScanModale from "../../components/quiz/paper/scan_modale.vue"
import EvalmeeQuizEvents from "./evalmee_quiz_events.vue"
import OpenQuestionGrader from "../../components/questions/open_question_grader.vue"
import StudentGlobalComment from "../../components/questions/student_global_comment.vue"
import ExerciseStudent from "../../components/quiz/editor/preview/exercise_preview.vue"
import instanceExerciseForMixin from "../../components/quiz/evalmee/instance_exercise_for_mixin"
import OutOfTimeSeveralAlert from "../../components/questions/out_of_time_several_alert.vue"
import CheatScoreAlert from "./cheat-score-alert.vue"
import LegacyCorrection from "./legacy-correction.vue"
import helpers from "../../helpers"
import ScoreCards from "./ScoreCards.vue"
import KatexMd from "../../components/katex-md.vue"
import axiosHelpers from "../../helpers/axios_helpers"
import EvalmeeQuizEventsReport from "./evalmee_quiz_events_report.vue"
import GetFeatureMixin from "../../components/shared/get_feature_mixin"
import EvalmeeQuizEventsPairTimeline from "./evalmee_quiz_events_pair_timeline.vue"
import UserDevices from "./user_devices/user_devices.vue"
import TimeAccommodationChip from "@/components/quiz/time_accommodations/TimeAccommodationChip.vue"
import { useScore } from "@/composables/useScore"
import { blurNames } from "@/composables/useDevUtils"
import timeHelpers from "../../helpers/time_helpers"
import ws from "@/web_sockets"

export default {
  name: "Correction",
  props: {
    scoreId: { type: Number, default: null },
    quizId: { type: Number, default: null },
    quizzesAttemptSummaryId: { type: Number, default: null },
    teacherMode: { type: Boolean, default: false },
    teacherBackRouteName: { type: String, default: "quizTeacherScores" },
  },
  mixins: [instanceExerciseForMixin, GetFeatureMixin],
  setup() {
    return {
      useScore,
      blurNames: blurNames(),
    }
  },
  data() {
    return {
      scanModal: false,
      scoreFetched: false,
      showEvents: false,
      state: "",
      downloadingCorrection: false,
    }
  },

  components: {
    TimeAccommodationChip,
    UserDevices,
    EvalmeeQuizEventsPairTimeline,
    EvalmeeQuizEventsReport,
    ScoreCards,
    LegacyCorrection,
    CheatScoreAlert,
    OutOfTimeSeveralAlert,
    ExerciseStudent,
    EvalmeeQuizEvents,
    OpenQuestionGrader,
    ScanModale,
    preloader,
    StudentGlobalComment,
    KatexMd,
  },

  methods: {
    ...mapActions([
      "fetchScoresByQuiz",
      "fetchScore",
      "fetchQuiz",
      "fetchAnswers",
      "fetchMyInstanceByQuiz",
      "fetchInstancesByQuiz",
      "fetchPaperSheet",
      "fetchQuestionsByQuiz",
      "fetchScoreSummary",
    ]),
    fetchScores() {
      if (!this.quiz) { return }
      if (this.scoreFetched) return

      // We cannot only check is score is present because
      // a score collections includes less fields
      this.scoreId ?
        this.fetchScoreSummary({ scoreId: this.scoreId }) :
        this.fetchScoresByQuiz(this.quiz)

      if (this.questions?.length === 0) {
        this.fetchQuestionsByQuiz(this.quiz)
      }
      this.scoreFetched = true
    },
    downloadCopyWithCorrection() {
      this.downloadingCorrection = true

      axiosHelpers.downloadFile({
        url: this.score.links.export_copy_with_correction,
        filename: `${this.quiz.name}_${this.score.name}.pdf`,
      }).then(() => this.downloadingCorrection = false)
    },

    fetchCorrection() {
      if (!this.quiz) { return }
      if (!this.userId) { return }
      if (this.state === "loading") return
      if (this.state === "loaded") return

      this.loading()

      if (this.quiz.quiz_type === "paper") {
        return this.loaded()
        // Dorian - 2024-10-13
        // Code bellow is temporary disabled after PaperQuizSheet migration to use subscription_id
        // instead of user_id

        // return this.fetchPaperSheet({
        //   quiz: this.quiz,
        //   userIds: [this.userId],
        // }).then(this.loaded)
      }

      if (this.teacherMode) {
        this.fetchInstancesByQuiz({
          quiz: this.quiz,
          userIds: [this.userId],
        }).then(this.loaded)
      } else {
        this.fetchMyInstanceByQuiz({
          quiz: this.quiz,
          quizzesAttemptSummary: this.quizzesAttemptSummary,
        }).then(this.loaded)
      }

    },

    loaded() {
      this.state = "loaded"
    },

    loading() {
      this.state = "loading"
    },

    back() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push(this.backRoute)
      }
    },

    // DUPLICATE-#5
    updateScoresLiveUpdateSubscriptions(scoreIds) {
      if(ws.subscriptions.TeachersScores) {
        return ws.subscriptions.TeachersScores.followScores(scoreIds)
      }
      ws.channels.TeachersScores.subscribe({ quizId: this.quiz.id, scoreIds })
    },
  },

  mounted() {
    if (this.quiz == null) {this.fetchQuiz(this.quizId)}
  },
  watch: {
    quiz: {
      handler() {this.fetchScores()},
      immediate: true,
    },
    userId: {
      handler() {this.fetchCorrection()},
      immediate: true,
    },
    score: {
      handler(val) {
        if (!val?.id) return
        if(this.teacherMode) {
          this.updateScoresLiveUpdateSubscriptions([val.id])
        } else {
          this.fetchCorrection()
        }
      },
      immediate: true,
    },
  },

  computed: {
    helpers() {
      return helpers
    },
    timeHelpers() {
      return timeHelpers
    },
    ...mapGetters([
      "quizById",
      "questionsByQuizId",
      "currentCorrectionLoading",
      "getUserById",
      "scoreByQuizAndUserId",
      "currentUser",
      "scoreById",
      "answersByQuestionsAndUserId",
      "answerByQuestionAndUserId",
      "quizInstanceByQuizId2",
      "studentEvents",
      "quizzesAttemptSummaryById",
    ]),
    quiz() { return this.quizById(parseInt(this.quizId)) },
    questions() {
      return this.questionsByQuizId(this.quizId).sort((a, b) => a.id - b.id)
    },
    backRoute() {
      if (this.teacherMode) {
        return {
          name: this.teacherBackRouteName,
          params: { quizId: this.quizId },
        }
      } else {
        return { name: "examsIndex" }
      }
    },
    userId() {
      if (this.scoreId) {
        return this.score?.user_id
      } else {
        return this.currentUser?.id
      }
    },
    score() {
      if (this.scoreId) {
        return this.scoreById(this.scoreId)
      } else {
        return this.scoreByQuizAndUserId(this.quizId, this.userId)
      }
    },
    timeAccommodation(){
      return this.useScore(this.score).timeAccommodation.value
    },
    paperQuiz() { return this?.quiz?.quiz_type === "paper"},
    answers() {
      return this.answersByQuestionsAndUserId(
        this.questions?.map(q => q.id),
        this.userId
      )
    },

    showLoader() {
      if (this.exercises.length > 0) return false
      return this.currentCorrectionLoading || this.instancesLoading
    },

    studentExamDuration() {
      if (!this.score.exam_duration) return null

      return helpers.scoreDuration(this.score.exam_duration)
    },
    showLegacyCorrection() {
      return !this.currentCorrectionLoading
        && !this.instancesLoading
        && this.paperQuiz
    },
    quizzesAttemptSummary() { return this.quizzesAttemptSummaryById(this.quizzesAttemptSummaryId) },
  },
}
</script>
